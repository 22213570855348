@import "antd/style";
@import '../../../node_modules/suneditor/dist/css/suneditor.min.css';

:root {
    --kit-color-secondary: #92c93f;
    --kit-color-primary: #283252;
    --kit-font-family: 'Mukta', sans-serif;
}

html {
    font-size: 15px;
}

body {
    font-size: 1rem;
    line-height: 1.5;
    font-family: $base-font-family;
    color: $text;
    overflow-x: hidden;
    position: relative;
    font-variant: normal;
    font-feature-settings: normal;
}

a {
    text-decoration: none;
    color: $text;
    @include transition-color();
    &:hover,
    &:active,
    &:focus {
        color: $blue;
        text-decoration: none;
    }
}

input {
    outline: none !important;
    font-family: $base-font-family;
    color: $text;
}

button,
input {
    box-shadow: none !important;
    outline: none !important;
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
    appearance: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $black;
}

svg {
    vertical-align: initial;
    overflow: auto;
}

.badge-example {
    font-size: rem(14);
    text-transform: uppercase;
    margin-bottom: rem(15);
    background: $gray-2;
    color: $black;
    display: inline-block;
    padding: rem(3) rem(6);
    border-radius: 4px;
}

.text-center { text-align: center; }
.text-right { text-align: right; }
.text-uppercase { text-transform: uppercase; }

.mr-1 { margin-right: 1rem; }
.mr-2 { margin-right: 2rem; }
.mb-1 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 2rem; }

.w-100 { width: 100% }

.float-right {
    float: right;
}

.ant-layout-header {
    border-bottom: 1px solid #f0f0f0;
    padding: 0.5rem 1em !important;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.10);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.10);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.10);

    .payment-plan-create-button {
        margin-top: 0.5rem;
        background: #198754;
        color: #FFF;
    }
}

.ant-menu-horizontal {
    border: 0px;
    &.main-menu {
        .ant-menu-item,
        .ant-menu-submenu {
            padding: 1rem 1rem;
        }
    }
}

.ant-table-thead > tr > th {
    background: none;
    border-width: 2px;
    font-weight: bold;
}

.ant-page-header {
    padding: 0;
}

.site-page-header {
    @extend .mb-2;
    border: 1px solid #d9d9d9;
    padding: 16px 24px;
}

.ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 2rem;
}

.ant-card-bordered {
    border: 1px solid #d9d9d9;
}

.cui__utils__content {
    padding: 2em;
}

.language-select {
    cursor: pointer;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
    color: var(--kit-color-primary);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
    border-bottom: 2px solid var(--kit-color-primary);
}

.dg-table {
    padding: 0;
    margin: 0;

    &.dg-table-border {
        border: 1px solid #d9d9d9;
    }


    tr {
        th {
            text-align: left;
            padding: 5px;
        }

        td {
            padding: 5px;
            border-bottom: 1px solid #d9d9d9;
        }
        &:last-child {
            td {
                border: 0;
            }
        }
    }
}

.criteriaMatched {
    .ant-card-body {
        padding-top: 0;
        padding-bottom: 0;
        .ant-form-item {
            margin-bottom: 0;
        }
    }
}

.ant-menu-horizontal.main-menu .ant-menu-item,
.ant-menu-horizontal.main-menu .ant-menu-submenu {
    padding: .5rem .6rem;
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
    margin-left: .5rem;
}

.strong-labels label {
    font-size: 1.2em;
    font-weight: bold;
}
