.ant-select {
  &-open {
    .ant-select-selection {
      border-color: $primary;
    }
  }

  &-selection {
    &:hover {
      border-color: $primary;
    }
  }
}

