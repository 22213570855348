// this is shared mixins file for all components from the app,
// there you can easily add your own variables / imports or redefine
// existing without touching default packages for future updates

@import 'core/mixins.scss'; // import KIT mixins

// $text: #000;
// $success: green;

.ant-form-item-label label{
  width: 100% !important;
}


[data-kit-theme="default"] .ant-table-thead > tr > th {
  background: var(--kit-color-primary);
  color: $white;
}

[data-kit-theme="default"] .ant-table-thead th.ant-table-column-has-sorters:hover,
[data-kit-theme="default"] .ant-table-thead th.ant-table-column-sort {
  background: var(--kit-color-secondary);
}

[data-kit-theme="default"] .ant-table-column-sorter {
  color: $white;
}

[data-kit-theme="default"] .ant-table-column-sorter-up.active,
[data-kit-theme="default"] .ant-table-column-sorter-down.active {
  color: var(--kit-color-primary);
}

[data-kit-theme="default"] .ant-divider-horizontal.ant-divider-with-text {
  font-size: 18px;
}

[data-kit-theme="default"] .ant-input .form-control {
  border: 0;
}
